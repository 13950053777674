<!-- Finder particular de Apds -->

<template>
  <div class="exp_Apd_F" v-if="schema">
    <v-sheet :elevation="4">

      <!-- Cabecera -->
      <div class="cabecera" v-if="Entorno.header.header">       
        <base_Header 
          :Entorno="Entorno.header"
          @onEvent="event_Header">          
        </base_Header>
      </div>

      <!-- Contenido -->
      <div class="contenedor"> 

        <!-- Filtro de Busqueda -->
        <!-- <div @focusin="focusinFiltro()" :style="Entorno.filtro.style" :class="Entorno.filtro.class"> -->
        <div :style="Entorno.filtro.style" :class="Entorno.filtro.class">
          <div class="columna">
            <div style="display:flex">
              <v-checkbox
                v-show="!hideMapfre"
                v-bind="$checkbox"
                v-model="schema.ctrls.mf.value"
                :label="schema.ctrls.mf.label">
              </v-checkbox>
            </div>

            <div style="display:flex">
              <v-text-field
                style="flex: 0 0 10%"
                v-bind="$input"
                v-model="schema.ctrls.clavep.value"
                :label="schema.ctrls.clavep.label"
                readonly>                                
              </v-text-field>
              
              <ctrlfinder
                style="flex: 0 0 45%"
                finderName="muni_F"
                :fn="extraid_Cpo"
                :schema="schema.ctrls.origen">                  
              </ctrlfinder>
              
              <v-text-field
                style="flex: 0 0 20%"
                v-bind="$input"
                v-model="schema.ctrls.cpo.value"
                :label="schema.ctrls.cpo.label">                                
              </v-text-field>
              
              <v-btn v-bind="$cfg.btn.busca" @click="event_search('buscarApd')">
                <v-icon dark>{{ $cfg.btn.busca.icono }}</v-icon>
              </v-btn>  

              <v-checkbox                   
                style="flex: 0 0 30%"            
                v-bind="$checkbox"
                v-model="schema.ctrls.guardia.value"
                :label="schema.ctrls.guardia.label">                
              </v-checkbox>              
            </div>
          </div>
        </div>

        <!-- Información de Agencia -->
        <div>
          <div style="display:flex">
            <v-sheet width="776px" height="190px" v-bind="$cfg.styles.marco">
              <div class="cab">AGENCIA</div>
              <v-text-field           
                v-bind="$input"
                v-model="schInfo.agencia"
                label="Nombre"
                readonly>                                           
              </v-text-field>

              <v-text-field           
                v-bind="$input"
                v-model="schInfo.ramo"
                label="PROCEDIMIENTO ACTUACIÓN CA24H"
                readonly>                                            
              </v-text-field>

              <v-textarea
                v-bind="$textarea"
                v-model="schInfo.apd"
                label=""
                readonly
                rows="1"
                auto-grow>                                           
              </v-textarea>             
            </v-sheet>

            <v-sheet width="200px" height="190px" v-bind="$cfg.styles.marco">
              <div class="cab">TELÉFONOS</div>
              <v-text-field           
                v-bind="$input"
                v-model="schInfo.tlf1"
                label="Teléfono 1"
                readonly>
              </v-text-field>

              <v-text-field           
                v-bind="$input"
                v-model="schInfo.tlf2"
                label="Teléfono 2"
                readonly>                                            
              </v-text-field>

              <v-text-field           
                v-bind="$input"
                v-model="schInfo.tlf3"
                label="Teléfono 3"
                readonly>                                           
              </v-text-field>
            </v-sheet>
          </div>

          <div style="display:flex">
            <v-sheet width="776px" height="60px" v-bind="$cfg.styles.marco">
              <div class="cab">FUNERARIA</div>
              <v-text-field           
                v-bind="$input"
                v-model="schInfo.fun_name"
                label="Nombre"
                readonly>                                           
              </v-text-field>
            </v-sheet>

            <v-sheet width="200px" height="110px" v-bind="$cfg.styles.marco">
              <div class="cab">TELÉFONOS</div>
              <v-text-field           
                v-bind="$input"
                v-model="schInfo.fun_tlf1"
                label="Teléfono 1"
                readonly>                                            
              </v-text-field>

              <v-text-field           
                v-bind="$input"
                v-model="schInfo.fun_tlf2"
                label="Teléfono 2"
                readonly>                                           
              </v-text-field>
            </v-sheet>
          </div>
        </div>

        <!-- Grid -->
        <base_Fgrid :padre="stName" :Entorno="Entorno" @onEvent="event_Grid">
          <!-- Slot botonera Top -->
          <template v-slot:top_btns="{ Sitem }">         
            <div style="padding:10px 0 0 10px;">
              <btracombi
                :btra_permisos="schema.btra"
                :btra_particular="Entorno.btra"
                btra_modulo="top"
                @onEvent="event_btra_Top({ accion:$event.accion, row:Sitem })">
              </btracombi>
            </div>
          </template>
          

          <!-- Slot campo acciones del Grid -->
          <template v-slot:acciones_grid="{ Sitem }">
            <btracombi
              :btra_permisos="schema.btra"
              :btra_particular="Entorno.btra"
              btra_modulo="mrow"
              @onEvent="event_btra_Row({ accion:$event.accion, row:Sitem })">
            </btracombi>
          </template>
        </base_Fgrid>
      </div>
    </v-sheet>
  </div>
</template>


<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base"); 
  const base_Fgrid = () => plugs.groute("base_Fgrid.vue", "base");
  const btracombi = () => plugs.groute("btracombi.vue", "comp");
  const ctrlfinder = () => plugs.groute("ctrlfinder.vue", "comp");  

  export default {
    mixins: [mixinFinder],
    components: { base_Header, base_Fgrid, btracombi, ctrlfinder },
    props: {
      padre: { type:String, default: ''},
      componenteTipo: { type:String, default:'FM' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },


    data() {
      return {
        schema:null,
        api:'exp_Apd_F',
        stName:'stFexp_Apd',
        component_M:null,
        modal:false,
        accion:'',
        accionRow: {},        
        disparo:false,

        headers: {},
        hideMapfre: false,
        schInfo: {
          agencia:'',
          codigo:'',
          pobla:'',
          aviso_funeraria:false,         
          fun_name:'',
          aviso_traslado:false,
          apd:'',
          obs:"",
          tlf1:"",
          tlf2:"",
          tlf3:"",
          fun_tlf1:"",
          fun_tlf2:"",
          ramo:"",
          ramoMsg:"RAMO 038: COMUNICADOS COMO NEGOCIO DIRECTO/FACTURAS A AFS"
        }
      };
    },


    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");
        
        // guardo Schema del store en variable local
        this.schema = this.$store.state[this.stName].schema;

        // configuro Entorno 
        this.Entorno.header.titulo = "Búsqueda de APDS";  

        this.headers = {
          header: [
            { text: "Estado", value: "std", witdh: "10rem" },
            { text: "Nombre", value: "name", witdh: "10rem" },
            { text: "Código", value: "codigo", witdh: "10rem" },
            { text: "Teléfono", value: "tlf1", witdh: "10rem" },
            { text: "Móvil", value: "mov1", witdh: "10rem" },
            { text: "Agencia", value: "afecta", witdh: "10rem" },
            { text: "Agencia Nombre", value: "agencia", witdh: "10rem" },
            { text: "Último Exp.", value: "fhhr", witdh: "10rem" },
            { text: "Exp.", value: "exped", witdh: "10rem" },
            { text: "Acciones", value: "acciones", sortable: false, width: "auto" }
          ],

        };

        this.Entorno.grid.headers = this.set_headers();       
      },  

      
      //
      ini_schemaCtrls() {
        // 
        if (!this.padre) return;
        
        var ctrls= this.$store.state[this.padre].schema.ctrls;

        // si es una póliza de Mapfre, marco el checkbox 'mf' y lo oculto
        if (ctrls.callemp_tp.value== 2 && ctrls.callemp.value== 83) {
          this.schema.ctrls.mf.value= "1";     
          this.hideMapfre= true;
        }

        // guardo datos en el filtro
        this.schema.ctrls.clavep.value= ctrls.clavep.value;
      },


      // asigno código postal del origen extraido
      extraid_Cpo(r) {        
        this.schema.ctrls.cpo.value= r.cp;
      },


      // gestor de eventos
      async event_search() {
        var ctrls= this.schema.ctrls;

        //
        var args= { tipo:'fnc', accion: 'apds', fn_args: { accion:'agenda_busqueda', 
          clave: this.hideMapfre? ctrls.clavep.value : '999', 
          cpo: ctrls.cpo.value,
          todos: ctrls.guardia.value}
        };

        var apiResult= await this.buscarExecFnc(args);
        if (apiResult.sql.error) return;
        this.search_back(apiResult.r);
      },


      //
      search_back(records) {
        // guardo datos en la info
        this.schInfo.agencia= records[1].afecta_name;
        if (this.$store.state[this.padre].schema.ctrls.ramo.value== "038") this.schInfo.ramo= this.schInfo.ramoMsg;    

        // Mostrar tipo de tratamiento APD        
        var afecta_tipo= this.$store.state.datos_iniciales.afecta_tipo;
                
        if (Number(records[1].afecta_apd)!= 4) {
          for (var i=0; i< afecta_tipo.length; i++) {          
            if (Number(records[1].afecta_apd)== afecta_tipo[i].id) {
              this.schInfo.apd= afecta_tipo[i].name+ " " + afecta_tipo[i].accionca24h;
              break;
            }
          }
        }

        this.schInfo.tlf1= records[1].tlf1;
        this.schInfo.tlf2= records[1].tlf2;
        this.schInfo.tlf3= records[1].tlf3;
        this.schInfo.fun_name= records[1].funes;
        this.schInfo.fun_tlf1= records[1].fun_tlf1;
        this.schInfo.fun_tlf2= records[1].fun_tlf2;
        this.schInfo.obs= records[1].obs;
      }
    }
  };
</script>
